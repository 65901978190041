import React, { useCallback, useEffect, useRef } from 'react';

import { IPost } from '../../../../types/strapi/posts';
import BlogArticles from '../../../BlogPage/components/BlogArticles';
import PageSection from '../../../PageSection';
import Content from '../../components/Content';
import NewsHead from '../../components/NewsHead';

interface IProps {
    post: IPost,
    posts: Array<IPost> | null,
    showToggler: boolean,
    onView: () => void,
    onActivate: (id: number) => void,
    setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>,
    isDarkTheme: boolean
}

const NewsPage = ({ post, posts, onView, onActivate, showToggler, setIsDarkTheme, isDarkTheme }: IProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    let triggered = false;
    let lastScrollTop = 0;

    const handleScroll = useCallback(() => {
        if(!containerRef?.current) {
            return;
        }

        const st = window.pageYOffset || document.documentElement.scrollTop;
        let scrollDown = true;
        if(st > lastScrollTop) {
            scrollDown = true;
        } else if(st < lastScrollTop) {
            scrollDown = false;
        }
        lastScrollTop = st <= 0 ? 0 : st;

        if(
            containerRef.current.getBoundingClientRect().top > 0
            && containerRef.current.getBoundingClientRect().top < window.innerHeight
        ) {
            if(scrollDown && history.state?.id !== post.id) {
                history.pushState({ id: post.id }, '', `/news/${post.code}`);
            }

            if(!triggered) {
                // onView();
                triggered = true;
            }

            onActivate(post.id);
        } else if(
            !scrollDown
            && history.state?.id !== post.id
            && containerRef.current.getBoundingClientRect().bottom < window.innerHeight
            && containerRef.current.getBoundingClientRect().bottom > 0) {
            history.pushState({ id: post.id }, '', `/news/${post.id}`);
            onActivate(post.id);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [onView]);

    return (
        <div ref={containerRef}>
            <PageSection removeBelowOffset={true} className="news__head">
                <NewsHead
                    post={post}
                    showToggler={showToggler}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                />
            </PageSection>
            <PageSection removeBelowOffset={true}>
                <Content post={post} />
            </PageSection>
            {posts?.length ? (
                <PageSection>
                    <BlogArticles items={posts} carousel={true} head={true} headText="Читайте также" />
                </PageSection>
            )
                : ''}
        </div>
    );
};

export default NewsPage;
