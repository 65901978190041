import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useClassnames } from '../../../../hooks/use-classnames';
import Arrow from '../../../BlogPage/components/BlogArticles/assets/arrow.inline.svg';
import GridWrapper from '../../../grid-wrapper';

import './index.css';

const rootClassName = 'news-slider';

interface IProps {
    items: Array<{ url: string }>,
    index: number
}

const NavigationButton = ({ index }: { index: number }) => {
    const cn = useClassnames();
    return (
        <div className={cn(`${rootClassName}__navigation`)}>
            <button
                className={cn(`${rootClassName}__navigation-prev ${rootClassName}__navigation-prev_${index} ${rootClassName}__navigation-btn`)}
            >
                <Arrow />
            </button>
            <button
                className={cn(`${rootClassName}__navigation-next ${rootClassName}__navigation-next_${index} ${rootClassName}__navigation-btn`)}
            >
                <Arrow />
            </button>
        </div>
    );
};

const Item = ({ url, mime }: { url: string, mime: string }) => {
    const contentType = mime.split('/')[0];

    return (
        <div className={`${rootClassName}__slide-item`}>
            {contentType === 'video' ? (
                <video width="100%" height="100%" autoPlay={true} loop={true} muted={true}>
                    <source src={url} />
                </video>
            ) : (
                <picture>
                    <img src={url} alt="slide-img" width="100%" height="100%" />
                </picture>
            )}
        </div>
    );
};

const Slider = ({ index, items }: IProps) => {
    const cn = useClassnames();
    return (
        <GridWrapper className={cn(`${rootClassName}`)}>
            <Swiper
                direction="horizontal"
                className={cn(`${rootClassName}__carousel`, items.length > 1 ? `${rootClassName}__carousel_with-arrows` : '')}
                slidesPerView={1}
                centeredSlides={false}
                spaceBetween={0}
                modules={[Navigation]}
                loop={true}
                navigation={{
                    prevEl: `.${rootClassName}__navigation-prev_${index}`,
                    nextEl: `.${rootClassName}__navigation-next_${index}`
                }}
            >
                {items.map((item, index) => (
                    <SwiperSlide key={index} className={`${rootClassName}__slide`}>
                        <Item {...item} />
                    </SwiperSlide>
                ))}
            </Swiper>
            {items.length > 1 && <NavigationButton index={index} />}
        </GridWrapper>
    );
};

export default Slider;
