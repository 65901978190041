import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import './index.css';
import IconShare from "./assets/share.png";
import Text from "../../../text";
import {useClassnames} from "../../../../hooks/use-classnames";
import InSvg from './assets/in.inline.svg';
import ShareSvg from './assets/share.inline.svg';
import TgSvg from './assets/tg.inline.svg';
import VkSvg from './assets/vk.inline.svg';

const mainClass = 'share'

const buttons = [
    {
        id:'vk',
        icon: <VkSvg/>,
        href: `https://vk.com/share.php?url=`
    },
    {
        id:'tg',
        icon: <TgSvg/>,
        href: `https://t.me/share/url?url=`
    },
    {
        id:'copy',
        icon: <ShareSvg/>,
        href: ""
    },
]

const Share = ({setActive}) => {

    const inputCopy = useRef()
    const [newsUrl,setNewsUrl] = useState()

    const openWindowShare = useCallback((url)=>{
        window.open(`${url}`, "share", "width=600,height=400");
    },[])

    const fallbackCopyURL = useCallback((url) => {
        const input = inputCopy.current;
        input.value = url;
        input.select();
        document.execCommand("copy");
    }, [])

    useEffect(()=>{
        setNewsUrl(window.location.href)
    },[])

    const copyURL = useCallback(() => {
        const url = window.location.href;
        if (!navigator.clipboard) {
            fallbackCopyURL(url);
            return;
        }
        navigator.clipboard.writeText(url).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });

    }, [])

    const buttonList = buttons.map(({icon, href,id}, index) => (
        <button className={`${mainClass}__button-share ${mainClass}__button-share_${id}`} key={'button-' + index}
           onClick={(e) => {
               setActive(false);
               if(id === 'copy'){
                   e.preventDefault()
                   copyURL()
               }else{
                   openWindowShare(href+newsUrl);
               }
           }}>{icon}</button>))



    return (
        <div className={`${mainClass}`}>
            {buttonList}
            <input ref={inputCopy} style={{position: 'fixed', opacity: '0', pointerEvents: 'none'}}/>
        </div>
    );
}

export default Share;
