import React, { CSSProperties, useMemo } from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { IPost } from '../../../../types/strapi/posts';
import { formatDateTextShort, stripHtmlTags } from '../../../../utils';
import Heading from '../../../heading';
import { Picture } from '../../../Picture';
import Text from '../../../text';

import BackSvg from './assets/arrow-back.inline.svg';
import Clock from './assets/clock.inline.svg';
import Theme from './assets/theme.inline.svg';

import './index.css';


const rootClassName = 'news-head';
const mainClass = 'content';

interface IProps {
    post: IPost,
    showToggler: boolean,
    setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>,
    isDarkTheme: boolean
}

const stringWithoutBrTags = (value: string) => value.replace(/<br\s*\/?>/gi, '');

const NewsHead = ({ post, showToggler, setIsDarkTheme, isDarkTheme }: IProps) => {
    const cn = useClassnames();

    const tags = (obj: IPost, mod: string) => (
        <ul className={`${rootClassName}__item-tags ${rootClassName}__item-tags_${mod}`}>
            {obj.tags.map(({ text, id }, index) => (
                <li key={index}>
                    <a href={`/blog?tag=${id}`}>
                        <Text
                            className={cn(`${rootClassName}__item-tags-item`)}
                            size={5}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </a>
                </li>
            ))}
        </ul>
    );

    const gradientColor = useMemo((): CSSProperties => {
        return {
            color: post.type.colorHex
        };
    }, [post]);

    return (
        <div className={`${rootClassName}`}>
            <Heading
                className={`${rootClassName}__title`}
                level={2}
                as="h1"
                dangerouslySetInnerHTML={{ __html: stringWithoutBrTags(post.title) }}
            />

            <div className={`${rootClassName}__control-panel`}>
                <a className={`${rootClassName}__back`} href="/blog">
                    <BackSvg />
                    <Text
                        className={cn(`${rootClassName}__back-text`)}
                        size={5}
                        dangerouslySetInnerHTML={{ __html: 'Назад в блог' }}
                    />
                </a>

                <button
                    className={`${rootClassName}__button ${rootClassName}__button_theme`}
                    onClick={() => {
                        setIsDarkTheme(!isDarkTheme);
                    }}
                ><Theme />
                </button>
            </div>

            <div className={`${rootClassName}__item`}>
                <div className={cn(`${rootClassName}__item-img`, {
                    [`${rootClassName}__item-img_${post.type.value}`]: post.type.value
                })}
                >
                    <div>
                        <Picture image={post.headerImage} alt={post.headerImage.alt ?? 'item-img'} />
                        {post.author && (
                            <div className={`${rootClassName}__item-person`}>
                                <div className={`${rootClassName}__item-person-img`}>
                                    <Picture image={post.author.image} alt={post.author.image.alt ?? 'item-img'} />
                                </div>
                            </div>
                        )}
                    </div>
                    {tags(post, 'mob')}
                </div>

                <div className={`${rootClassName}__item-info`}>
                    {tags(post, 'desk')}
                    <div className={`${rootClassName}__gradient`} style={gradientColor} />
                    <div className={`${rootClassName}__item-info-time`}>
                        <Text
                            className={cn(`${rootClassName}__info-type ${rootClassName}__info-type_${post.type.value}`)}
                            size={5}
                            colorHex={post.type.colorHex}
                            dangerouslySetInnerHTML={{ __html: post.type.text }}
                        />
                        <Text
                            className={cn(`${rootClassName}__info-date`)}
                            size={5}
                            dangerouslySetInnerHTML={{ __html: formatDateTextShort(post.date) }}
                        />
                        <div className={`${rootClassName}__info-time-wrapper`}>
                            <Clock />
                            <Text
                                className={cn(`${rootClassName}__info-time`)}
                                size={5}
                                dangerouslySetInnerHTML={{ __html: `${post.readingTime} чтения` }}
                            />
                        </div>
                        <button
                            className={cn(`${mainClass}__share-btn`)} onClick={() => {
                                setPopupActive((prev) => !prev);
                            }}
                        >
                            <Text
                                className={cn(`${mainClass}__share-btn-text`)}
                                size={4}
                                dangerouslySetInnerHTML={{ __html: 'Поделиться' }}
                            />
                        </button>
                    </div>
                    <Text
                        className={cn(`${rootClassName}__info-name`)}
                        size={2}
                        dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                </div>

            </div>
        </div>
    );
};

export default NewsHead;
