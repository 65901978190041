import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { IGlobalServerRuntimeData } from '../../types/strapi/global';
import { IPost } from '../../types/strapi/posts';
// import Carousel from '../carousel';
import Footer from '../footer';
import Layout from '../layout';

import Article from './components/Article';

import './index.css';
import { stripHtmlTags } from '../../utils';

interface IProps {
    post: IPost,
    posts: Array<IPost> | null,
    data: IGlobalServerRuntimeData
}

function getDefaultTheme(): boolean {
    const theme = window.localStorage.getItem('theme');
    return theme === 'dark';
}

const NewsPage = ({ post, data, posts }: IProps) => {
    const seo = {
        metaTitle      : stripHtmlTags(post.title),
        metaDescription: stripHtmlTags(post.leadParagraph)
    };

    const rootClassName = 'news-page';
    const wrapper = `${rootClassName}__wrapper`;

    const [postDetails, setPostDetails] = useState([{ post, posts }]);
    const [activeId, setActiveId] = useState(post.id);
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    let isLoading = false;
    let load = true;

    const loadMore = useCallback(() => {
        if(!isLoading && load) {
            const lastPost = postDetails[postDetails.length - 1].post;

            isLoading = true;
            void fetch(
                `/api/article?date=${lastPost.date}&id=${lastPost.id}`
            ).then(async (res) => {
                const data = await res.json();
                if(data?.data?.post) {
                    setPostDetails([...postDetails, {
                        post : data.data.post,
                        posts: data.data.posts
                    }]);
                    isLoading = false;
                } else {
                    load = false;
                }
            });
        }
    }, [postDetails]);

    const handleView = function() {
        loadMore();
    };

    const handleActivate = function(id: number) {
        setActiveId(id);
    };

    useEffect(() => {
        if(isDarkTheme) {
            document.querySelector('.app-layout')?.classList.add('dark');
        } else {
            document.querySelector('.app-layout')?.classList.remove('dark');
        }
        window.localStorage.setItem('theme', isDarkTheme ? 'dark' : 'light');
    }, [isDarkTheme]);

    useLayoutEffect(() => {
        const isDark = getDefaultTheme();

        setIsDarkTheme(isDark);

        if(isDark) {
            document.querySelector('.app-layout')?.classList.add('dark');
        }
    }, [setIsDarkTheme]);

    return (
        <Layout
            seo={seo}
            hasContactButton={true}
            navPanel={data.navPanel}
            global={data.global}
            respondForm={data.respondForm}
            hasProgressBar={true}
            isDarkTheme={isDarkTheme}
        >
            {postDetails.map((detail, index) => (
                <Article
                    key={index}
                    post={detail.post}
                    posts={detail.posts}
                    onView={handleView}
                    onActivate={handleActivate}
                    showToggler={detail.post.id === activeId}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                />
            ))}
            {/* {post.slider && <Carousel data={post.slider} className="news-page__carousel" />}*/}
            <Footer className={`news-page__footer ${wrapper}`} data={data.footer} />
        </Layout>
    );
};

export default NewsPage;
