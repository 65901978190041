import { GetServerDataProps, GetServerDataReturn } from 'gatsby';
import fetch from 'node-fetch';
import React from 'react';

import NewsPage from '../../components/NewsPage';
import { GLOBAL_QUERY } from '../../constants';
import { TPageProp } from '../../types';
import { IGlobalServerRuntimeData } from '../../types/strapi/global';
import { IPost } from '../../types/strapi/posts';

// eslint-disable-next-line import/order
import 'swiper/swiper-bundle.css';

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    post: IPost,
    posts: Array<IPost> | null
}

type TProp = TPageProp<IServerRuntimeData>;

const News: React.FC<TProp> = ({ serverData }) => {
    return serverData ? (
        <NewsPage post={serverData.post} posts={serverData.posts} data={serverData} />
    ) : null;
};

export default News;

const query = `
  query Post ($locale: String, $code: String!) {
    ${GLOBAL_QUERY}
    post: postByCode(code: $code) {
      id
      code
      title
      date
      readingTime
      leadParagraph
      headerImage {
          desktopSmall {
            url
          }
          desktopNormal {
            url
          }
          mobile {
            url
          }
          tablet {
            url
          }
        alt
      }
      author {
        image {
          desktopSmall {
            url
          }
          desktopNormal {
            url
          }
          mobile {
            url
          }
          tablet {
            url
          }
          alt
        }
        name
        surname
      }
      tags(sort: "sort:asc") {
        id
        value
        text
      }
      type {
        value
        colorHex
        text
      }
      contentBlock {
        __typename
        ... on ComponentPostText {
          paragraphs {
            text
          }
        }
        ...on ComponentPostKeyPhrase {
          phrase
        }
        ... on ComponentPostSlider {
          slider_items {
            url
            mime
          }
        }
        ... on ComponentPostList {
          list {
            text
          }
        }
        ... on ComponentPostQuote {
          text
          name
          surname
          description
          image {
            desktopSmall {
              url
            }
            desktopNormal {
              url
            }
            mobile {
              url
            }
            tablet {
              url
            }
            alt
          }
        }
      }
    }
  }
`;

export async function getPosts(id: number, tags: Array<string>): Promise<Array<IPost> | null> {
    if (!Boolean(tags.length)) return [];

    const query = `
          query getPosts ($locale: String, $where: JSON, $limit: Int) {
            posts (locale: $locale, limit: $limit, sort: "date:desc", where: $where) {
              id
              code
              title
              date
              readingTime
              previewImage {
                desktopNormal {
                    url
                }
                alt
              }
              author {
                image {
                  desktopNormal {
                    url
                  }
                  alt
                }
                name
                surname
              }
              tags(sort: "sort:asc") {
                id
                value
                text
              }
              type {
                value
                colorHex
                text
              }
            }
          }
        `;

    const where = {
        '_locale'   : process.env.GATSBY_LOCALE_CODE,
        'isArchived': false,
        'tags'      : {
            'value_in': tags
        },
        'id_ne': id
    };

    const variables = JSON.stringify({
        where,
        limit : 4,
        locale: process.env.GATSBY_LOCALE_CODE
    });

    const response = await fetch(
        `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
    );

    const data: { data: IServerRuntimeData } = await response.json();

    return data.data.posts ?? null;
}

export async function getServerData({ params }: GetServerDataProps): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE,
            code  : params?.code
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        if(!data.data.post || data.data.post.isArchived) {
            return {
                status: 404
            };
        }

        const post = data.data.post;

        return {
            props: {
                post       : post,
                posts      : await getPosts(post.id, post.tags.map((tag) => tag.value)),
                navPanel   : data.data.navPanel,
                global     : data.data.global,
                respondForm: data.data.respondForm,
                footer     : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
